import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/HomeView.vue"), //首页
  },
  {
    path: "/phoneLogin",
    name: "phoneLogin",
    component: () => import("../views/phone/phoneLogin.vue"), //移动端登录页
  },
  {
    path: "/phoneRegister",
    name: "phoneRegister",
    component: () => import("../views/phone/phoneRegister.vue"), //移动端注册页
  },
  {
    path: "/phoneForget",
    name: "phoneForget",
    component: () => import("../views/phone/phoneForget.vue"), //移动端忘记密码
  },
  {
    path: "/registerSuccess",
    name: "registerSuccess",
    component: () => import("../views/phone/registerSuccess.vue"), //移动端注册成功
  },
  {
    path: "/identityAuth",
    name: "identityAuth",
    component: () => import("../views/phone/identityAuth.vue"), //移动端身份认证
  },
  {
    path: "/phonePrivacy",
    name: "phonePrivacy",
    component: () => import("../views/phone/phonePrivacy.vue"), //移动端隐私政策
  },
  {
    path: "/phoneService",
    name: "phoneService",
    component: () => import("../views/phone/phoneService.vue"), //移动端用户协议
  },
  {
    path: "/phoneChangePass",
    name: "phoneChangePass",
    component: () => import("../views/phone/phoneChangePass.vue"), //移动端修改密码
  },
  {
    path: "/webPrivacy",
    name: "webPrivacy",
    component: () => import("../views/web/webPrivacy.vue"), //web隐私政策
  },
  {
    path: "/webService",
    name: "webService",
    component: () => import("../views/web/webService.vue"), //web服务协议
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
